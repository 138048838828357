import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "../Layout/Layout"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import { AppContext } from "../../context/AppContext"
import ConsentAndAuthorization from "elements/ConsentAndAuthorization"

import {
  GASTBY_ENCRYPTED_KEY,
  GATSBY_ENCRYPTED_VALUE,
} from "gatsby-env-variables"

const EnrollmentMechanics = props => {
  const { module, nextPath, backPath, acknowledgement, basePath } = props.pageContext
  const { state, dispatch } = useContext(AppContext)

  const handleFormSubmit = values => {
    sessionStorage.setItem(GASTBY_ENCRYPTED_KEY, GATSBY_ENCRYPTED_VALUE)
    dispatch({
      type: "SAVE_ACKNOWLEDGEDMENT",
      payload: values.hasAcknowledge,
    })

    navigate(`/${module.name}/${nextPath}`)
  }

  useEffect(() => {
    if (module.name === "doctor") {
      navigate(basePath)
    }
  }, [module])

  let layoutTitle = (
    <p className="is-size-1 is-size-4-mobile">Acknowledgement</p>
  )

  return (
    <Layout title={layoutTitle} seoTitle={`${module.seoTitle} Acknowledgement`}>
      <Container isCentered>
        <div
          className="mb-2"
          dangerouslySetInnerHTML={{
            __html: acknowledgement.replace(/\n/g, "<br />"),
          }}
        ></div>
        <ConsentAndAuthorization
          module={module.name}
          handleOnSubmit={handleFormSubmit}
        >
          <ActionButtons
            back={{
              label: "Back",
              link: backPath,
            }}
            submit={{
              label: "Continue",
              disabled: state?.consentAndAuth.length === 0,
            }}
          />
        </ConsentAndAuthorization>
      </Container>
    </Layout>
  )
}

export default EnrollmentMechanics
